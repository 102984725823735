<template>
  <div class="search_top">
    <div class="search_home">
      <div class="container">
        <div class="mt-8">
          <validation-observer ref="observer">
            <form @submit.prevent="validateAndSearch()" action="">
              <div class="row nomargin">
                <div class="selectbox">
                  <div class="width_50_search">
                    <validation-provider
                      name="This field"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <base-input
                        v-model="f_query"
                        placeholder="What do you want to search?"
                        class="query_input"
                        @change="canSearch = false"
                        :error="errors[0]"
                        @focus="isFocusInput = true"
                        @blur="isFocusInput = false"
                      ></base-input>
                    </validation-provider>
                  </div>
                  <div class="width_30_search">
                    <multi-select
                      multiple
                      label="country_name"
                      track-by="record_number"
                      :close-on-select="false"
                      :options="capsulatedOptions"
                      :max-height="150"
                      open-direction="below"
                      v-model="search_data.country_list"
                      @input="updateSearchData"
                      placeholder="Select country"
                      ref="select"
                      @open="isFocusSelect = true"
                      @close="isFocusSelect = false"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span
                            v-if="props.option.$isLabel"
                            class="option__title"
                            >{{ props.option.$groupLabel }}</span
                          >
                          <span v-else class="option__title">
                            {{ props.option.country_name }}</span
                          >
                        </div>
                      </template>
                      <div
                        class="multiselect__tags-wrap"
                        slot="selection"
                        slot-scope="{ values }"
                      >
                        <span
                          v-for="(item, index) in values"
                          class="multiselect__tag"
                          :key="'tag' + index"
                        >
                          <b>,</b>
                          <span>{{ item.country_name }}</span>
                        </span>
                      </div>
                    </multi-select>
                  </div>

                  <div class="width_20_search">
                    <base-button
                      id="btn-searchs"
                      @click="validateAndSearch()"
                      type="info"
                      size="lg"
                      ><span class="fa fa-search"> </span
                      >SherlockIT</base-button
                    >
                  </div>
                </div>
                <div class="infobutton ml-1" @click="modal_status = true">
                  <span id="clickhere">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QgNAhUagRR4jQAABJ5JREFUaN7tmVtMFGcUx/9nlh3cBRsCtluLBZXCoEA0YECURtNWUIqXVB5qrDw0SiXuukIVSkispRgrBotxuUg1JtpqLEmDNCUu1+oDEW9JwS23iForLiW0KV5QZndOHwgvGtNZyy6s6f/5/M+c75fvfLchTLJ0BVvf7wkMDnZafdZyWHExZ2Etji1bRn14gzJ0Oo7B73z84kXYnTYszc+Xd1S8Jz1qb5+o79NkDVwsNZd3HZUkDDpNgqW1FQeQxWcCA59rCOd9CB4dpXyeBltq6pOPyrVSQlPTf61DmCwAvNkZQDV79/7rwMfVS/m4K4psJKYjpaUTVcekAcBPMGBBfLzLvkeUw3nR0UBmZv8Wvd5rAdBeGBAzNOSyMQ2vUeKDB8DMIzOrHj/2WgBKLedwelWVqz7OgAWwWIAviEhRvBaAY3ZZvaSprIQIE7r37Blf5J4JFGFCNzN2YS7ePX5cXje6dti6e/dE1TFpu8DT8mUzd/Ls2cp5ZY3mm8RE5CNLidfp0KB86pPb1ib7l3W+VW+zTXad/+tlkwdb4HNmFgT9239/26U3GBw/KAGc4++vjaeqaaHDww9vHvpxbubAwEsAwFTXa/T1FT9QmvlUaiqfoFhesnEjHUQHNSxfjiLc5/agoKddfBmX0XLjBmuQiwsHDzqiLesjvquoAAAi5ikPQGzc1tbdnpODFlpCGQUFqk94zxHnci5aSkrkorIQaevOne4CMGHbIE+jowg1GrEPEm+w27mQC/FJfT0/hBl9nZ2u5qNy+hj3s7N915nqeo1hYVMYwFhv+8zAAFcnJY0qll+lvKgo+bOyQOnnlBRZawmXVs2fjwuYAamyUnXaB4jEGkFQgpQg7k5KmsIAxk5kI5GW2sjN/f3Pi+JoPoO0lhaX0zeggddrtVMYgEqtEEq4yN/fVRt9JcQK1923O3gOwGq+T4Xz5rkMYIOwynG4o8PrAZAdmXhVfS9zMRfTmnv3ntAhisTt214MwMw3OSAANZhOvYsWqXXRLQxwnNUKuPcc4HYAotZpfNK1YgXsWMcVPj5qfZxDwOZz59xdn/tnwB6OFU6vXKk6PhDJOOB0yiH8h2JrbPRqAMxE/DqlcXpKimpTBuz45dIloCxhnukFXoymCgBtaVZjj19MDGXhS/gGB6s2nuAZWOD+qe92APSKJpN2JCe76lOi2YFFzc1eD4Az+DQMCQmqDSEIoXdk2dHoO0v/8OpVTwFQvSq7ToACOSIqSnX4KXzI39+5g/ivQ95cOjLiKQDuWwT3IwWuXIdn4RTlOxyeGrj7AWxEP/kND6sNp1jM54iwsLHr77Nrh98c8+q+KoNBq92+vbMzLm7qAzjGPRxTW6s6/k/UY5dGw3Vcp5isVrHAqOlZODQk7jf+1d03OCjfdYbKy+x27nV2UfgL/FHyNAC5SLguXC8sxGIshvnaNZcTjL8oNaABeXo90ugYJZ48KW7SbuLpNTUTVacHHkWzW++U6HRipiw+8jObcYtD6Xx6OvypAlZJQjdn82+iyFugobihISzEPdZcuYJt1AS/s2dlG80SEqqrgcOp4Rb1LaVW/wActc2hn3nmygAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOC0xM1QwMjoyMToyNiswMDowMEp8NfUAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDgtMTNUMDI6MjE6MjYrMDA6MDA7IY1JAAAAAElFTkSuQmCC"
                      alt="Sherlock IT"
                    />
                  </span>
                  <!---->
                </div>
              </div>
              <input type="submit" style="display: none" />
            </form>
          </validation-observer>
          <div class="mt-1">
            <div v-if="!hideBadges">
              <country-badge
                :country_list="country_list"
                :selected="badge_list"
                @update:onSelect="onChangeCountry"
              ></country-badge>
            </div>
          </div>
        </div>
        <div v-if="search_result">
          <p class="text-default ">
            We found <strong>{{ search_result.matches }}</strong> entries in
            <strong>{{ search_result.time }}</strong>
            milliseconds matching your query
            <span v-if="search_result.search_allocation"
              >(<strong>{{ search_result.search_allocation.remaining }}</strong>
              queries remaining).</span
            >
          </p>
        </div>
        <div v-if="loading" class="text-center mt-4 mb-4">
          <spinner type="grow" size="lg" />
        </div>
      </div>
    </div>
    <div class="slectedtag">
      <div class="container">
        <div class="width_80">
          <div class="row filter_row">
            <div
              class="max-content_width"
              v-for="(item, index) in search_data.country_list"
              :key="'country' + index"
              v-show="item['country_name'] != 'All countries'"
            >
              {{ item["country_name"]
              }}<i
                aria-hidden="true"
                class="fa fa-times"
                @click="removeOne(index)"
              ></i>
            </div>
            <div class="reset_all" @click="removeFilter()">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QgeBC4tQzq2eQAAAxlJREFUSMe1VWtIU2EYfr9zdj5Fs8JUwphBFy9gVtRK/BEhFZXdMWuGGbUNdeeYSEpQIEWiVKI5aWtaiZHWkIaWAyXCjAJJKsnVztmFKOwCqXkhwl3e/jS7QDF1Pb+f93mej+fl/QBmDMFi5+fOpRsKlkuVSUmhlYXDTkdcHIBG3feU4wJVIYGQEAnhGgWLeDU7mzzBGOIRBGiFkzCuUMAEJMIuhpkiK+EzfBwbg0/AkYvt7RAHxzGjvHzSWLc2Ic9mm0aA0nhbQ0QE1/g1kcm7fZvwcAHvbN2KrfAazB0d0IsmiDKZ8CPrxRdOJxuHViYqLAz7fV1Yo1BgMfGAQ6Ui2XCLZMnlvgHSDw2C4AnV3YzfduXKP967fz8iy1KTFsXM7m56gr8hKYeHQyqEZEm5aVPgFZWhNZNS7rCwRtJUV1PK86KIyB3T9kqbc3P/OkbtWkFcWlREl/HnpUWTk7L8wgO2zWlpM1qTX3Wb+TniI6OR7uPPSpHj42GmApM1c+HC3xIjMgx3mT8uegYH6X1tr5huMMzW+Cc0aqdj3jx6imelVUNDXD9vFBXnzk0tjyx/5KC4JTWVFIEXnLGxPjVcIrrm5uAFMNYvXTY6CtGQAfPNZpIF96Bpxw6Ga+R3STKLhbnqiyZ1jx/76cwgiUTZw4dct7BTMiqVQcuRgYvx3cAArEYDRC5ZwriPeBd9e3boEIrQQGrtdj8Pj8IDsr262r1Rdzde09IStABfyB44SAiEkGKwITIAen1KysgIyfXJQb57NyzHCjC0tLgNC6yDjtLS4FXwA134hrDJyfAcTsMHlyugQxQc+JeQXvNmulyYBXrk9Hpm9sKBgdbQT15VVRW8hjJ4Syndw97l1Drdf7QULHY+JIRL5ztEbW3t1CE6o80WS3Jy/KxZVxDelNfpjImJ8fSyLu/zlSvxJWmCG+vWYRmaoU6lIoUQjrbYWHxPwoDyvHukjk2g9fX++VlX4O6TZXn2trVhPbHiRFcXJIIO55eUkOtkPXb29MAleOW7vmLFn8ZBhEYtGqKiQl/mDznMcvl0v+PvxAJNOFuZ/w8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDgtMzBUMDQ6NDY6NDUrMDA6MDDqgww9AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA4LTMwVDA0OjQ2OjQ1KzAwOjAwm960gQAAAABJRU5ErkJggg=="
              /><span> Reset all</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="width_80 mt-3 pt-3">
        <div id="search-result">
          <div>
            <div class="alerts">
              <base-alert
                type="danger"
                v-if="errors.no_search_remaining"
                dismissible
              >
                <span class="alert-inner--icon"
                  ><i class="fa fa-warning"></i
                ></span>
                <span class="alert-inner--text"
                  >You have reached the allocated number of search queries.
                  Please contact sherlockit support via
                  <router-link class="mylink" to="/contact-us"
                    >Contact Us</router-link
                  >
                  or email us on
                  <a class="mylink" :href="'mailto:support@sherlockit.net'"
                    >support@sherlockit.net</a
                  >
                </span>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
              <base-alert
                type="danger"
                v-if="errors.internal_server_error"
                dismissible
              >
                Oops! Something went wrong in the server.</base-alert
              >
            </div>
            <div v-if="search_result" class="mb-5" id="search-result">
              <div v-if="search_result.status == 1">
                <base-alert type="danger" dismissible>
                  <span class="alert-inner--icon"
                    ><i class="fa fa-warning"></i
                  ></span>
                  <span class="alert-inner--text">{{
                    search_result.reason
                  }}</span>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </base-alert>
              </div>

              <div v-if="get_search_results.length != 0">
                <div
                  v-for="(item, index) in get_search_results"
                  :key="index"
                  class="result-list-item mb-3"
                >
                  <div>
                    <h3 class="search_heading">
                      {{ page_size * (page_number - 1) + (index + 1) }}.
                      <router-link :to="showDetail(item, true)"
                        >{{ item["corporate_name"] }},
                        {{ item["country"] }}</router-link
                      >
                    </h3>
                  </div>
                  <div v-html="item.excerpt" class="excerpt"></div>
                  <div class="mt-1">
                    <span
                      @click="showDetail(item)"
                      class="btn-result btn-more-detail"
                      >More Details <i class="fa fa-info-circle"></i
                    ></span>
                    |
                    <span
                      @click="saveResult(item)"
                      class="btn-result btn-save-result"
                      >Save Record <i class="fa fa-save"></i
                    ></span>
                    <span class="float-right">
                      <span
                            class="history-last-updated"
                            v-b-tooltip.hover.bottom
                            :title="formatted_time(item.last_updated)"
                            >{{ from_now(item.last_updated) }}</span
                      >
                      <span v-if="
                            item.has_history &&
                            !history[index].loading">
                      |
                      </span>
                      <spinner
                        v-if="history[index].loading"
                        size="sm"
                        class="ml-2 mr-2"
                      />
                      <span
                        v-if="
                          !history[index].data &&
                            item.has_history &&
                            !history[index].error &&
                            !history[index].loading &&
                            !history[index].status
                        "
                        class="btn-result btn-more-detail"
                        type="info"
                        size="sm"
                        @click="showHistory(item, index)"
                      >
                        <i class="fa fa-refresh"></i> Show History
                      </span>
                      <span
                        v-if="
                          item.has_history &&
                            !history[index].loading &&
                            history[index].status
                        "
                        class="btn-result btn-more-detail"
                        type="info"
                        size="sm"
                        @click="hideHistory(index)"
                      >
                        <i class="fa fa-refresh"></i> Hide History
                      </span>
                      <span
                        v-else-if="history[index].error"
                        class="text-danger ml-3 mr-3"
                        >Could not get historical record
                        <i class="fa fa-warning"></i
                      ></span>
                    </span>
                  </div>
                  <div
                    class="container history-group"
                    v-if="history[index].data && item.has_history"
                  >
                    <hr style="width: 100%" />
                    <div
                      v-for="(h, hindex) in history[index].data"
                      :key="hindex"
                    >
                      <div class="history">
                        <strong
                          ><span
                            class="history-detail-link"
                            @click="showHistoryDetail(h)"
                            >{{ h.corporate_name }}</span
                          ></strong
                        >
                        <div class="float-right">
                          <span
                            class="history-last-updated"
                            v-b-tooltip.hover.bottom
                            :title="formatted_time(h.last_updated)"
                            >{{ from_now(h.last_updated) }}</span
                          >
                        </div>
                        <div class="mt-2">
                          <span
                            @click="showHistoryDetail(h)"
                            class="btn-result btn-more-detail"
                            >More Details <i class="fa fa-info-circle"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row divya">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <base-pagination
                      class="justify-content-center"
                      :page-count="page_count"
                      v-model="page_number"
                      iconLeft="ni ni-bold-left"
                      iconRight="ni ni-bold-right"
                      type="info"
                      first-text="First"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                    ></base-pagination>
                  </div>
                  <div class="col-sm-2 text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div
            v-if="!$store.getters.auth && search_result"
            class="mt-3 login-bar"
          >
            If you already have an account
            <span
              class="text-danger"
              style="cursor: pointer;"
              @click="redirectToLogin()"
              >Log In here
            </span>
          </div>
        </div>
      </div>
    </div>

    <base-button
      v-if="showScrollTop"
      type="primary"
      rounded
      iconOnly
      size="lg"
      @click="scrollTop()"
      class="floatingBtn"
      ><span class="fa fa-arrow-up"></span
    ></base-button>

    <modal :show.sync="modal_status">
      <div class="modal-backdrop" @click="modal_status = false">
        <div class="card-body">
          <button type="button" @click="modal_status = false" class="btn-close">
            &times;
          </button>
          <section class="modal-body">
            <div class="searchtips">
              <div class="srch_tips_div">
                <div class="top_img_serch text-center">
                  <img src="@/assets/img/home/lightbulb.png" />
                </div>
                <div class="heading_search text-center">
                  <h1><b>Tips</b> for search</h1>
                </div>
                <div class="infosech">
                  <ul class="card-bodyinner">
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Search for any phrase or combination of words:
                        <em> apple banana</em> (highest ranking for records with
                        exact phrase)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Search for records with accented characters: João,
                        résumé
                      </p>
                    </li>
                    <br />
                    <li class="fullwidth">
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Wild card (*) search (minimum of 3 characters required)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>APP*</em> search will match records that contains
                        any words starting with <em>APP</em>: Apple, App,
                        Appleby (including the word <em>APP</em>)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>*APP*</em> search will match records that contain
                        any words which have <em>APP</em> in any part of the
                        word (including the word <em>APP</em>)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>*APP</em> search will match records that contain any
                        words ending <em>APP</em> : Rapp, App (including the
                        word <em>APP</em>)
                      </p>
                    </li>
                  </ul>
                  <div class="gotit">
                    <a @click="modal_status = false" href="javascript:void(0)"
                      >GOT IT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
const MultiSelect = () =>
  import(/* webpackChunkName: "MultiSelect"*/ "vue-multiselect");
import moment from "moment";
import Modal from "@/components/Modal.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import Spinner from "@/components/Spinner";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CountryBadge from "@/components/RecordCountBadge";
export default {
  created() {
    document.onkeydown = evt => {
      evt = evt || window.event;
      if (evt.keyCode === 13) {
        if (!this.isFocusSelect) {
          evt.preventDefault();
          this.validateAndSearch();
        }
      }
    };
  },
  components: {
    MultiSelect,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    CountryBadge,
    Modal
  },
  directives: {
    BTooltip: VBTooltip
  },
  data: function() {
    return {
      canSearch: false,
      search_data: {
        query: "",
        country_list: []
      },
      country_list: [],
      search_result: {
        matches: null
      },
      page_number: 1,
      modal_status: false,
      page_size: 20,
      loading: false,
      prev_Data: [],
      badge_list: [],
      errors: {
        no_search_remaining: false,
        internal_server_error: false
      },
      showScrollTop: false,
      cl_url: [], //country_list from url
      meta: {
        title: "Search | SherlockIT"
      },
      all_countries: {
        country_name: "All countries",
        record_number: ""
      },
      results: [],
      history: [],
      isFocusInput: false,
      isFocusSelect: false,
      hideBadges: false,
      time_format: "YYYY-MM-DD hh:mm:ssTZZ",
    };
  },
  metaInfo() {
    return {
      title: this.meta.title
    };
  },
  mounted() {
    this.processURLData();
    this.updateCountryList(true);
    this.updateTitle();
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    f_query: {
      get() {
        return this.search_data.query;
      },
      set(data) {
        this.search_data.query = data;
      }
    },
    f_country_list: {
      get() {
        return this.search_data.country_list;
      },
      set(data) {
        this.search_data.country_list = data;
      }
    },
    page_count() {
      if (!this.search_result) {
        return 1;
      }
      return Math.ceil(this.search_result.results.length / this.page_size);
    },
    get_search_results() {
      let from = this.page_size * (this.page_number - 1);
      let to = from + this.page_size;
      if (this.search_result && this.search_result.results) {
        return this.search_result.results.slice(from, to);
      } else {
        return "";
      }
    },
    validated_search_data() {
      let country_list = [];

      if (
        this.search_data.country_list.length == 0 ||
        (this.search_data.country_list.length == 1 &&
          this.search_data.country_list[0].country_name == "All countries")
      ) {
        //Object.assign(this.search_data.country_list, this.country_list);
        country_list = [];
      } else {
        this.search_data.country_list.forEach(e => {
          country_list.push(parseInt(e.record_number));
        });
      }

      return {
        query: this.search_data.query,
        country_list: country_list
      };
    },
    capsulatedOptions() {
      return [this.all_countries, ...this.country_list];
    }
  },
  methods: {
    handleScroll() {
      this.showScrollTop = scrollY >= window.innerHeight / 2;
    },
    updateSearchData(value) {
      //remove All countries selection
      let index = this.search_data.country_list.indexOf(this.all_countries);
      if (this.prev_Data.indexOf(this.all_countries) != -1) {
        if (index != -1 && this.search_data.country_list.length > 1) {
          this.search_data.country_list.splice(index, 1);
        }
      } else if (index != -1) {
        this.search_data.country_list = [];
        this.search_data.country_list.push(this.all_countries);
      }
      this.prev_Data = value;
    },
    removeFilter() {
      while (this.search_data.country_list.length) {
        this.search_data.country_list.pop();
      }
      this.f_query = "";
    },
    removeOne(index) {
      this.search_data.country_list.splice(index, 1);
      if (this.canSearch) {
        this.search();
      }
    },
    processURLData() {
      //check for query in url
      if (this.$route.query.q) {
        this.f_query = this.$route.query.q;
      } else {
        this.f_query = "";
        this.search_result = null;
      }
      //check for country list in url
      var country_list = [];
      if (this.$route.query.c) {
        country_list = this.$route.query.c.split(",");
        for (var i in country_list) {
          if (!parseInt(country_list[i])) {
            country_list.splice(i, 1);
          }
        }
      }

      this.cl_url = country_list;
    },
    processURLCountry() {
      if (this.cl_url) {
        for (var i in this.cl_url) {
          let c = this.getCountry(this.cl_url[i]);
          if (c) {
            this.cl_url[i] = c;
          } else {
            this.cl_url.splice(i, 1);
          }
        }
        if (this.cl_url.length) this.search_data.country_list = this.cl_url;
      }
    },
    search_data_for_url(type) {
      let data = {};
      Object.assign(data, this.validated_search_data);
      data.country_list =
        data.country_list.length == this.country_list.length
          ? ""
          : data.country_list.join(",");
      if (type == "obj") {
        return {
          q: data.query,
          c: data.country_list
        };
      } else {
        return `q=${data.query}&c=${data.country_list}`;
      }
    },
    loadFromSearchData(res) {
      if (res.country_list) {
        if (res.country_list.length != 0) {
          this.country_list.forEach(e => {
            let c_res = res.country_list.find(
              c => c.record_number == e.record_number
            );
            if (c_res) {
              e.results_count = c_res.results_count;
            } else {
              e.results_count = 0;
            }
          });
        }
      }
      this.search_result = res;
      this.history = [];
      this.hideBadges = res.results.length > 0 ? false : true;
      for (var i = 0; i < this.search_result.results.length; i++) {
        this.history.push({
          loading: false,
          data: null,
          error: false,
          status: false
        });
      }
      this.loading = false;
      if (res.page_number) this.page_number = res.page_number;
      else this.page_number = 1;

      if (
        this.search_data &&
        this.search_data.country_list.length > 0 &&
        this.search_data.country_list[0].record_number !== ""
      ) {
        this.badge_list = this.search_data.country_list;
      } else if (this.search_data.country_list.length === 0) {
        this.badge_list = this.search_result.country_list;
      }
      // this.$store.commit("search_url", this.$route.fullPath);
    },
    search() {
      this.canSearch = true;
      this.badge_list = [...this.search_data.country_list];
      if (
        !this.search_data.country_list.length ||
        this.search_data.country_list[0].country_name == "All countries"
      ) {
        this.country_list.forEach(item => this.badge_list.push(item));
      }
      if (this.validated_search_data.query == "") return;
      this.loading = true;
      this.search_result = null;
      this.removeErrors();

      this.$axios
        .post("search/", this.validated_search_data)
        .then(res => {
          this.loadFromSearchData(res.data);
          this.$store.commit("set_search_cache", {
            ...res.data,
            query_country_list: this.validated_search_data.country_list
          });
        })
        .catch(err => {
          this.loading = false;
          let err_res = err.response.data;
          if (err_res.error == "no_search_remaining") {
            this.errors.no_search_remaining = true;
          }
          if (err.response.status == 500) {
            this.errors.internal_server_error = true;
          }
        });
    },
    validateAndSearch() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.updateURL();
        this.updateTitle();
      });
    },
    getCountry(record_number) {
      for (var i in this.country_list) {
        if (this.country_list[i].record_number == record_number) {
          return this.country_list[i];
        }
      }
      return null;
    },
    sortCountryList() {
      this.country_list.sort((a, b) =>
        (a["country_name"] || "")
          .toString()
          .localeCompare((b["country_name"] || "").toString())
      );
    },
    updateCountryList(mounted) {
      this.$axios.get("search/country-list/").then(res => {
        // this.country_list.push(...res.data);
        this.country_list = res.data;
        this.sortCountryList();
        this.processURLCountry();
        if (mounted && this.f_query) {
          let search_cache = this.$store.getters.search_cache;
          let country_list = [];
          if (this.$route.query.c)
            country_list = this.$route.query.c.split(",");
          const equalsIgnoreOrder = (a, b) => {
            if (a.length !== b.length) return false;
            const uniqueValues = new Set([...a, ...b]);
            for (const v of uniqueValues) {
              const aCount = a.filter(e => e == v).length;
              const bCount = b.filter(e => e == v).length;
              if (aCount !== bCount) return false;
            }
            return true;
          };
          if (
            search_cache.query == this.f_query &&
            equalsIgnoreOrder(search_cache.query_country_list, country_list)
          )
            this.loadFromSearchData(search_cache);
          else this.search();
        }
      });
    },
    reset() {
      this.f_query = "";
      this.search_data.country_list = [];
      this.$refs.observer.reset();
      this.search_result = null;
      this.meta.title = "Search | SherlockIT";
      this.updateCountryList();
      if (this.$router.path != "/search") {
        this.$router.push("/search");
      }
    },
    showDetail(data, linkOnly) {
      this.$store.commit("set_search_page", this.page_number);
      if (linkOnly) {
        return `/search/detail/${data.urn}-${data.country_code}?q=${this.f_query}`;
      }
      this.$router.push(
        `/search/detail/${data.urn}-${data.country_code}?q=${this.f_query}`
      );
    },
    removeErrors() {
      for (var e in this.errors) {
        this.errors[e] = false;
      }
    },
    saveResult(data) {
      this.$axios
        .post("search/save/", {
          country_code: data.country_code,
          urn: data.urn,
          query: this.f_query
        })
        .then(res => {
          if (res.status == 200) {
            this.$toasted.show(`Record saved successfully`, {
              type: "success",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        })
        .catch(err => {
          if (err.response) {
            this.$toasted.show(`Could not save record`, {
              type: "error",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        });
    },
    scrollTop() {
      document.querySelector("#app").scrollIntoView({
        behavior: "smooth"
      });
    },
    redirectToLogin() {
      if (!this.$store.getters.auth) {
        this.$store.commit(
          "after_login",
          `/search?${this.search_data_for_url()}`
        );
        this.$router.push("/login");
      }
    },
    updateTitle() {
      if (this.f_query) {
        this.meta.title = `${this.f_query} - SherlockIT`;
      } else {
        this.meta.title = "Search | SherlockIT";
      }
    },
    updateURL() {
      let data = this.search_data_for_url("obj");
      if (this.$route.query.q != data.q || this.$route.query.c != data.c) {
        this.$router.push(`/search?${this.search_data_for_url()}`);
      } else {
        this.search();
      }
    },
    hideHistory(index) {
      this.history[index].data = "";
      this.history[index].status = false;
      this.history[index].loading = false;
    },
    showHistory(item, index) {
      this.history[index].loading = true;
      this.history[index].status = true;
      this.$axios
        .get(`search/history/${item.urn}-${item.country_code}`)
        .then(res => {
          this.history[index].data = res.data;
          this.history[index].data = res.data.reverse();
          this.history[index].loading = false;
        })
        .catch(err => {
          if (err.response) {
            this.history[index].error = true;
            this.history[index].loading = false;
          }
        });
    },
    formatted_time(d) {
      return moment(d,this.time_format).format("lll");
    },
    from_now(d) {
      return moment(d,this.time_format).fromNow();
    },
    showHistoryDetail(h) {
      this.$router.push(`/search/detail/${h.urn}-${h.country_code}`);
    },
    onChangeCountry(value) {
      const countries = [];

      const country = {
        country_name: value.country_name,
        record_number: value.record_number
      };
      countries.push(country);
      this.search_data.country_list = countries;
      let data = this.search_data_for_url("obj");
      if (this.$route.query.q != data.q || this.$route.query.c != data.c) {
        this.$router.push(`/search?${this.search_data_for_url()}`);
      } else {
        this.search();
      }
    }
  },
  watch: {
    $route() {
      this.processURLData();
      this.updateTitle();
      this.processURLCountry();
      // this.updateCountryList();
      if (this.f_query) {
        let search_cache = this.$store.getters.search_cache;
        let country_list = [];
        if (this.$route.query.c) country_list = this.$route.query.c.split(",");
        const equals = (a, b) => JSON.stringify(a) == JSON.stringify(b);
        if (
          search_cache.query == this.f_query &&
          equals(search_cache.query_country_list, country_list)
        )
          this.loadFromSearchData(search_cache);
        else this.search();
      }
    },
    show() {
      const body = document.querySelector("body");

      if (this.show === false && body.style.overflow === "hidden") {
        body.style.overflow = "";
        window.removeEventListener("keyup", this.onEscapeKeyUp);
      } else {
        body.style.overflow = "hidden";
        window.addEventListener("keyup", this.onEscapeKeyUp);
      }
    }
  }
};
</script>
<style scoped>
h3 {
  font-size: 20px;
  line-height: 1.3;
}

h3:hover,
.btn-result:hover,
.history-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.excerpt {
  font-size: 14px;
}

.btn-more-detail {
  color: rgb(25, 56, 49);
}

.btn-save-result {
  color: #3f8753;
}

.history-btn {
  border: 1px solid rgb(38, 162, 100);
  background-color: rgb(38, 162, 100);
}

.history {
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
  position: relative;
}

.history::after {
  content: "";
  position: absolute;
  width: 17px;
  background-color: lightskyblue;
  bottom: 0;
  left: 0;
  top: 28%;
  margin-left: -15px;
  /* border-width: 10px 0 10px 10px; */
  height: 1px;
}

.history:hover {
  background-color: #cde1e2;
  /* cursor: pointer; */
}

.history-group {
  margin-top: -15px;
  border-left: 1px solid lightskyblue;
}

.historical-record {
  font-size: 18px;
}

.history-detail-link {
  color: #37a9e0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.cursor-pointer:hover,
.history-detail-link:hover {
  cursor: pointer;
}
</style>
